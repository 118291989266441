import styles from './Product.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomerTypes } from 'behavior/user/constants';
import { UseSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { makeRichText } from 'utils/render';

const WarehouseStockLevel = ({ stockLevel, customerType, businessSegment, onRendered, warehouseTitle,
  warehouseClassName = '', useDefaultWarehouseText = false }) => {
  const className = getStockLevelClassName(stockLevel);
  const stockValuePlaceholder = <Placeholder className={styles.stockValuePlaceholder} />;

  let textKey = `StockLevelText_${stockLevel}`;
  const customText = `${textKey}_${businessSegment}`;
  if (!customerType || customerType === CustomerTypes.B2C)
    textKey += '_B2C';
  else
    textKey += '_B2B';

  const sanaTextsToLoad = [textKey, customText, 'WarehouseText_DefaultWarehouseText'];

  return (
    <UseSanaTexts options={sanaTextsToLoad} placeholder={stockValuePlaceholder}>
      {([stockText, customText, defaultWarehouseText]) => {
        onRendered && onRendered();
        const warehouseText = (useDefaultWarehouseText && stockLevel === 'OutOfStock') ? defaultWarehouseText : warehouseTitle;
        return (stockText && (
          <>
            <span className={`${styles.stockValue} ${className}`}>
              {makeRichText((customText || stockText))}
            </span>
            {warehouseText && <span className={warehouseClassName}>{warehouseText}</span>}
          </>
        ));
      }}
    </UseSanaTexts>
  );
};

function getStockLevelClassName(stockLevel){
  switch (stockLevel)
  {
    case 'InStock':
      return styles.inStock;
    case 'LowStock':
      return styles.lowStock;
    default:
      return styles.outStock;
  }
}

WarehouseStockLevel.propTypes = {
  stockLevel: PropTypes.string,
  warehouseTitle: PropTypes.string,
  customerType: PropTypes.string,
  businessSegment: PropTypes.string,
  onRendered: PropTypes.func,
  warehouseClassName: PropTypes.string,
  useDefaultWarehouseText: PropTypes.bool,
};

export default connect(({ user: { customerType, businessSegment } }) => ({ customerType, businessSegment }))(WarehouseStockLevel);