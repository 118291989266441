import styles from './ProductPrice.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { renderHTML } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { getCurrency, getFormatPrice } from 'utils/format';
import { useCurrencyInfo } from 'utils/hooks';

const ProductCoreChargePrice = ({ coreChargePrice }) => {
  const currencyInfo = useCurrencyInfo();
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const formattedPrice = useMemo(() => {
    if (!currencyInfo)
      return null;

    return renderHTML(formatPrice(formatAsPrice(coreChargePrice), currencyInfo));
  }, [currencyInfo, coreChargePrice]);

  const { texts: [
    priceLbl,
  ], loaded } = useSanaTexts([
    'ActionPrice_Label',
  ]);

  if (!loaded || formattedPrice == null)
    return <Placeholder className="price-block product-price-placeholder" />;

  const currencySymbol = getCurrency(currencyInfo.id, currencyInfo.cultureName);

  return (
      <Col xs="auto" className="price-block" title={priceLbl}>
        <i className="visually-hidden">{priceLbl} {currencySymbol + coreChargePrice}</i>
        <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
          {formattedPrice}
        </span>
      </Col>
    );
};

ProductCoreChargePrice.propTypes = {
  coreChargePrice: PropTypes.number,
};

export default memo(ProductCoreChargePrice);

function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}
