import styles from './Product.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomerTypes } from 'behavior/user/constants';
import { UseSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { makeRichText } from 'utils/render';

const ProductStock = ({ inventory, stockLevels, customerType, businessSegment, onRendered, forVariant, warehouseTitle,
  warehouseClassName = '', useDefaultWarehouseText = false }) => {
  const { outOfStock, lowStock, maxStockNumber } = stockLevels;
  let className = '';
  let sanaTextsToLoad = [];

  const stockValuePlaceholder = <Placeholder className={styles.stockValuePlaceholder} />;

  if (typeof inventory !== 'undefined') {
    let textKey = 'StockText_';

    if (inventory <= outOfStock) {
      textKey += 'OutOfStock';
      className = styles.outStock;
    }
    else if (inventory > lowStock) {
      textKey += 'InStock';
      className = styles.inStock;
    }
    else {
      textKey += 'LowStock';
      className = styles.lowStock;
    }

    const customText = `${textKey}_${businessSegment}`;

    if (!customerType || customerType === CustomerTypes.B2C)
      textKey += '_B2C';
    else
      textKey += '_B2B';

    if (forVariant)
      textKey += '_Variant';

    sanaTextsToLoad = [textKey, customText, 'WarehouseText_DefaultWarehouseText'];

    if (maxStockNumber && maxStockNumber < inventory)
      sanaTextsToLoad.push('StockText_MaxStockNumber');
  } else {
    return stockValuePlaceholder;
  }

  return (
    <UseSanaTexts options={sanaTextsToLoad} placeholder={stockValuePlaceholder}>
      {([stockText, customText, defaultWarehouseText, maxStockNumberText]) => {
        onRendered && onRendered();
        const warehouseText = (useDefaultWarehouseText && inventory <= outOfStock) ? defaultWarehouseText : warehouseTitle;
        return (stockText && (
          <>
            <span className={`${styles.stockValue} ${className}`}>
              {makeRichText((customText || stockText).replace('[STOCKAMOUNT]', maxStockNumberText ? maxStockNumberText : inventory), [maxStockNumber])}
            </span>
            {warehouseText && <span className={warehouseClassName}>{warehouseText}</span>}
          </>
        ));
      }}
    </UseSanaTexts>
  );
};

ProductStock.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
  warehouseTitle: PropTypes.string,
  additionalClass: PropTypes.string,
  customerType: PropTypes.string,
  businessSegment: PropTypes.string,
  onRendered: PropTypes.func,
  forVariant: PropTypes.bool,
  warehouseClassName: PropTypes.string,
  useDefaultWarehouseText: PropTypes.bool,
};

export default connect(({ user: { customerType, businessSegment } }) => ({ customerType, businessSegment }))(ProductStock);
